<template>
  <div class="rechargeRecord">
    <div class="rechargeRecordBox">
      <van-list v-model:loading="formData.loading" :finished="formData.finished" :offset="30" finished-text="没有更多了"
        @load="onLoad">
        <div class="list" v-for="item in listData" :key="item.pay_id">
          <div class="title">
            <div class="name">
              <span v-if="item.pay_status == 10" class="havePayment">已支付</span>
              <span v-if="item.pay_status == 0" class="noPayment">未支付</span>
            </div>
            <div class="time">{{ item.create_at }}</div>
          </div>
          <div class="content">
            <div class="item">
              <span class="name">表号：</span>
              <span class="text">{{ item.meter_number }}</span>
            </div>
            <div class="item">
              <span class="name">充值{{ params.meter_type == 1 ? '吨' : '度' }}数：</span>
              <span class="text act">{{ parseFloat(item.pay_price / item.price).toFixed(2) }}{{ params.meter_type == 1 ?
                '吨' : '度' }}</span>
            </div>
            <div class="item">
              <span class="name">单价：</span>
              <span class="text act">{{ item.price }}元</span>
            </div>
            <div class="item">
              <span class="name">总金额：</span>
              <span class="text act">{{ item.pay_price }}元</span>
            </div>
            <div class="item" style="width: 100%">
              <span class="name">充值流水号：</span>
              <span class="text">{{ item.pay_no }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted, reactive } from 'vue';
import { getRechargeList } from '@/service/contribute';
const { router, route } = inject('ctx');

const listData = ref([]);
const formData = ref({
  loading: false,
  finished: false,
  pageTotal: 0
});
const params = reactive({
  pageNum: 1,
  pageSize: 10,
  meter_type: route.query.type
})

const getList = () => {
  getRechargeList(params).then(res => {
    formData.value.pageTotal = res.data.total;
    listData.value = res.data.list || [];
    formData.value.loading = false;
  });
}

const onLoad = () => {
  formData.value.loading = false;
  if (params.pageNum >= Math.ceil(formData.value.pageTotal / params.pageSize)) {
    formData.value.finished = true;
  } else {
    params.pageNum++;
    this.getList();
  }
}

onMounted(() => {
  getList();
});
</script>

<style lang="scss" scoped>
.rechargeRecord {
  padding: 20px 24px 0;
  background: #f9f9f9;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;

  .rechargeRecordBox {
    .list {
      padding: 0 20px 20px 20px;
      border-radius: 10px 10px 10px 10px;
      background-color: #fff;
      font-size: 26px;
      margin-bottom: 20px;

      .title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-between;

        .name {
          font-weight: bold;
          color: #000000;
          font-size: 32px;

          .havePayment {
            color: #85c226;
          }

          .noPayment {
            color: #ee0a24;
          }
        }

        .time {
          color: #999999;
        }
      }

      .content {
        padding: 20px 0 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          width: 50%;
          margin-bottom: 20px;
          display: flex;

          .name {
            width: 140px;
          }

          .act {
            color: #85c226;
          }
        }
      }
    }
  }
}
</style>