import * as request from '@/plugin/request';
const getLeftMenus = () => {
  return Promise.all([
    request.HttpGet('/h5/contract_fee_setting/listAll'),
    request.HttpGet('/h5/setting/dailySetting')
  ]);
};

/**
 *
 * @param {*} params 获取每项缴费的值
 * @returns
 */
const getFeeListById = (params) => {
  return request.HttpGet('/h5/bill_offline/offlineFeeIdBill', params);
};

const addPayPrice = (params) => {
  return request.HttpPost('/h5/bill_offline_v2/billOfflineV3', params);
};

const getAllFee = () => {
  return request.HttpPost('/h5/contract_fee_setting/listUnpaidAll');
};

/**
 * 查询表号的单价，并且查询是否存在这个表号
 * @param {参数} params 
 * @returns 
 */
const getMeterNumber = (params = {}) => {
  return request.HttpGet('/property_daily/merchant/dh_daily/get', params);
}

/**
 * 获取商户表列表
 * @param {参数} params 
 * @returns 
 */
const getMeterList = (params = {}) => {
  return request.HttpGet('/property_daily/merchant/meter/list', params);
}

/**
 * 充值
 * @param {参数} params 
 * @returns 
 */
const setRechargePay = (params = {}) => {
  return request.HttpPost('/property_daily/merchant/pay/add', params);
}

/**
 * 获取充值列表
 * @param {参数} params 
 * @returns 
 */
const getRechargeList = (params = {}) => {
  return request.HttpPost('/property_daily/merchant/pay/list', params);
}

/**
 * 根据表号查询余额
 * @param {参数} params 
 * @returns 
 */
const getinquire = (params = {}) => {
  return request.HttpPost('/property_daily/merchant/dh_daily/info', params);
}
export { getLeftMenus, getFeeListById, addPayPrice, getAllFee, getMeterNumber, getMeterList, setRechargePay, getRechargeList, getinquire };
